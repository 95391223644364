import React, {useRef} from 'react'

import {useIsVisible} from '@hook/useIsVisible'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'

import Text from '@component/Text/Text'
import WizImage from '@component/images/WizImage'
import FadeInView from '@component/animation/fadeIn/FadeInView'

import BeaconInfoImageEn from '@image/token/img_token_beacon_Info_en.webp'
import StakingInfoImageEn from '@image/token/img_token_staking_Info_en.webp'

interface IProps {
    className?: string
}

const TokenBeaconContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const language = useRouter()?.locale
    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)

    return (
        <div
            className={`layout-full_desktop max-w-[1280px] px-[20px] py-[150px] md:py-[100px] sm:py-[50px] land:px-[40px] md:px-[20px] space-y-[70px] md:space-y-[50px] sm:space-y-[30px] ${className}`}>
            <div ref={fadeAnimationRef} className={'flex flex-col items-center justify-center gap-y-[40px]'}>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN}>
                    <Text
                        className={
                            'text-white text-landing_heading2 text-center md:text-landing_body_large md:font-semibold !font-landing'
                        }>
                        {t('landingTokenSale.TokenInfo.title')}
                    </Text>
                </FadeInView>
            </div>

            <div className={'flex flex-col items-center justify-center gap-y-[40px]'}>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-500'}>
                    <Text
                        className={
                            'text-white text-landing_display1 text-center md:text-landing_body_small md:font-semibold !font-landing'
                        }>
                        {t('landingTokenSale.TokenInfo.beacon.title')}
                    </Text>
                </FadeInView>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-700'}>
                    <Text
                        className={
                            'text-[#C9CAC3] text-landing_body_medium text-center md:text-landing_body_caption !font-landing'
                        }>
                        {t('landingTokenSale.TokenInfo.beacon.desc')}
                    </Text>
                </FadeInView>
            </div>

            <div className={'flex flex-col items-center justify-center gap-y-[50px] md:gap-y-[30px]'}>
                {language === 'ko' ? <WizImage src={BeaconInfoImageEn} /> : <WizImage src={BeaconInfoImageEn} />}
            </div>

            <div
                className={
                    'flex flex-col items-center justify-center gap-y-[40px] !mt-[130px] md:!mt-[70px] sm:!mt-[60px]'
                }>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN}>
                    <Text
                        className={
                            'text-white text-landing_display1 text-center md:text-landing_body_small md:font-semibold !font-landing'
                        }>
                        {t('landingTokenSale.TokenInfo.social.staking.title')}
                    </Text>
                </FadeInView>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-700'}>
                    <Text
                        className={
                            'text-[#C9CAC3] text-landing_body_medium text-center md:text-landing_body_caption !font-landing'
                        }>
                        {t('landingTokenSale.TokenInfo.social.staking.desc')}
                    </Text>
                </FadeInView>
            </div>

            <div className={'flex flex-col items-center justify-center gap-y-[50px] md:gap-y-[30px]'}>
                {language === 'ko' ? <WizImage src={StakingInfoImageEn} /> : <WizImage src={StakingInfoImageEn} />}
            </div>
        </div>
    )
}
export default TokenBeaconContainer
